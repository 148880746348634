import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, interval, map, Observable, takeWhile} from "rxjs";
import {environment} from "../../../environments/environment";
import {UtilityService} from "../../shared/services/utility.service";
declare var _vwo_code: any;

@Injectable({
  providedIn: 'root'
})
export class VwoService {
  isWACampaign = new BehaviorSubject<any>(false);
  utility:UtilityService = inject(UtilityService)

  constructor() { }
  // init(){
  //   this.variation(environment.WACampaignID).subscribe(el => {
  //     if (el && el!=1 && this.utility.isWACampaign){
  //       this.isWACampaign.next(true);
  //     }else{
  //       this.isWACampaign.next(false);
  //     }
  //   });
  //   let value:any = this.getCookieValue(environment.WACampaignID)
  //   if (value && value!=1 && this.utility.isWACampaign){
  //     this.isWACampaign.next(true);
  //   }else{
  //     this.isWACampaign.next(false);
  //   }
  // }
  init() {
    const updateCampaignStatus = (value: any) => {
      const isValid = value && value != 1 && this.utility.isWACampaign;
      this.isWACampaign.next(isValid);
    };

    this.variation(environment.WACampaignID).subscribe(updateCampaignStatus);

    const initialValue = this.getCookieValue(environment.WACampaignID);
    updateCampaignStatus(initialValue);
  }

   getCookieValue(campaignId:any) {
    const name = `_vis_opt_exp_${campaignId}_combi`;
    const decodedCookie = decodeURIComponent(document.cookie);

    const ca = decodedCookie.split(';');
    let cookieValue = ca.find(el=>{
      let cookie = el.split('=')
      if(cookie[0].trim() == name) {
        return true
      } else{
        return null
      }
    });
    return cookieValue?.split('=')[1]
  }

  variation(campaignId:any): Observable<any>{
    return new Observable<any>((observer) => {
      const observable = interval(1000).pipe(
        takeWhile(() => !_vwo_code?.finished() || true)
      ).subscribe({
        complete: () => {
          observer.next(this.getCookieValue(campaignId));
          observer.complete();
        }
      });
      return () => observable.unsubscribe();
    });
  }

  finished(): Observable<void> {
    return new Observable<void>((observer) => {
      const observable = interval(1000).pipe(
        takeWhile(() => !_vwo_code?.finished() || true)
      ).subscribe({
        complete: () => {
          observer.next();
          observer.complete();
        }
      });
      return () => observable.unsubscribe();
    });
  }
}
