import { Observable } from 'rxjs';
import {PackageModel, PaymentLinkModel, PaymentLinkPayloadModel, WebsiteDataModel} from '@models';
import {SessionType, WebPageEnum} from "@enums";

export abstract class CommonRepository {
  abstract getWebsiteData(): Observable<WebsiteDataModel>;
  abstract getPackages(params: {session_type: SessionType,web_page:  WebPageEnum,isUniversity:boolean}): Observable<PackageModel[]>;
  abstract generatePaymentLink(payload: PaymentLinkPayloadModel): Observable<PaymentLinkModel>;
  abstract getPackagesTestimonials(): Observable<any>; // TODO - type the response
}
