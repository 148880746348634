import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public loading404: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public forceLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  get isLoading() {
    return this.loading.value || this.loading404.value ||this.forceLoading.value;
  }

  setLoading(value: boolean) {
    this.loading.next(value);
  }

  set404Loading(value: boolean) {
    this.loading404.next(value);
  }

  setForceLoading(value: boolean) {
    this.forceLoading.next(value);
  }
}
