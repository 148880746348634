import { Routes } from '@angular/router';

export const signupRoutes: Routes = [
  { path: 'lead-type', loadComponent: () => import('./sections').then(m => m.LeadTypeComponent) },
  { path: 'student-gender', loadComponent: () => import('./sections').then(m => m.StudentGenderComponent) },
  { path: 'tutoring-support', loadComponent: () => import('./sections').then(m => m.TutoringSupportComponent) },
  { path: 'subjects', loadComponent: () => import('./sections').then(m => m.SubjectComponent) },
  { path: 'educational-level', loadComponent: () => import('./sections').then(m => m.EducationLevelComponent) },
  { path: 'sub-levels/:subject', loadComponent: () => import('./sections').then(m => m.SubLevelsComponent) },
  { path: 'sub-levels', loadComponent: () => import('./sections').then(m => m.SubLevelsComponent) },
  { path: 'number-of-session', loadComponent: () => import('./sections').then(m => m.NumberOfSessionComponent) },
  { path: 'session-duration', loadComponent: () => import('./sections').then(m => m.SessionDurationComponent) },
  { path: 'curriculum', loadComponent: () => import('./sections').then(m => m.CurriculumComponent) },
  { path: 'tutor-gender', loadComponent: () => import('./sections').then(m => m.TutorGenderComponent) },
  { path: 'session-type', loadComponent: () => import('./sections').then(m => m.SessionTypeComponent) },
  { path: 'map', loadComponent: () => import('./sections').then(m => m.MapComponent) },
  { path: 'loader', loadComponent: () => import('./sections').then(m => m.LoaderComponent) },
  { path: 'tutor-card', loadComponent: () => import('./sections').then(m => m.TutorCardComponent) },
  { path: 'sign-up', loadComponent: () => import('./sections').then(m => m.SignUpComponent) },
  { path: 'packages', loadComponent: () => import('./sections').then(m => m.PackagesComponent) },
  { path: 'select-package', loadComponent: () => import('./sections').then(m => m.Packagesv2Component) },
  { path: 'result', loadComponent: () => import('./sections').then(m => m.ThankYouComponent) },
  // { path: '', redirectTo: 'lead-type', pathMatch: 'full' },
];
