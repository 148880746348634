import {
  AfterViewInit,
  APP_ID,
  Component,
  ElementRef,
  HostListener,
  inject,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { environment } from '../environments/environment';
import { ActivationStart, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { UtilityService } from './shared/services/utility.service';
import { ScriptInjectService } from './shared/services/script-inject.service';
import { GoogleAdsService } from './shared/services/google-ads.service';
import { SegmentService } from './shared/services/segment.service';
import {LoadingService, StorageService, TranslateFacadeService} from '@services';
import { filter, pairwise } from 'rxjs/operators';
import {Slugs} from "@enums";
import {VwoService} from "./core/services/vwo.service";
import {LoaderComponent} from "./core/components/loader/loader.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [RouterOutlet, LoaderComponent],
  providers: [
    { provide: APP_ID, useValue: 'serverApp' },
    { provide: 'googleTagManagerId', useValue: 'GTM-MLV38RF' },
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  hasUserInteracted = false;
  private readonly localizeRouterService = inject(LocalizeRouterService);
  private readonly translateFacadeService = inject(TranslateFacadeService);
  private readonly router = inject(Router);
  private readonly scriptInjectService = inject(ScriptInjectService);
  private readonly renderer = inject(Renderer2);
  private readonly utility = inject(UtilityService);
  private readonly segmentService = inject(SegmentService);
  private readonly googleAdsService = inject(GoogleAdsService);
  private readonly storageService = inject(StorageService);
  private readonly elementRef = inject(ElementRef);
  VwoService: VwoService = inject(VwoService)
  loading = inject(LoadingService);

  ngOnInit(): void {
    if (this.localizeRouterService.parser.currentLang === 'en') {
      this.utility.SEOService.setEnglishMetTag();
    } else {
      this.utility.SEOService.setArabicMetTag();
    }
    if (this.utility.route.url.startsWith('/trial-session')) {
      this.utility.setReferral('TS');
    }
    if (this.utility.route.url.startsWith('/advantages')) {
      this.storageService.setReferral('Advantages-main');
    }
    if (this.utility.route.url.startsWith('/advantages-sc')) {
      this.storageService.setReferral('advantages-sc');
    }
    if (this.utility.route.url.toLowerCase().startsWith('/learn/ataa')) {
      this.storageService.setReferral('Ataa');
    }
    if (this.utility.route.url.includes('/tutorbooking')) {
      this.utility.setReferral('tutorbooking');
    }
    if (this.utility.route.url.includes('/el/') && !this.utility.route.url.includes('registration-flow') && !this.utility.route.url.includes('registration-flow-sc') && !this.utility.route.url.includes('/step')) {
      this.utility.setReferral(this.utility.route.url.split('?')[0]?.replace(/^\//, ''));
    }
    if (this.utility.route.url.includes('/'+Slugs.market+'/') && !this.utility.route.url.includes('registration-flow') && !this.utility.route.url.includes('registration-flow-sc') && !this.utility.route.url.includes('/step')) {
      this.utility.setReferral(this.utility.route.url.split('?')[0]?.replace(/^\//, ''));
    }
    if (this.utility.route.url.includes('/live/')) {
      this.utility.setReferral(this.utility.route.url.split('?')[0]?.replace(/^\//, ''));
    }
    if (this.utility.route.url.includes('/'+Slugs.market+'/') && !this.utility.route.url.includes('registration-flow') && !this.utility.route.url.includes('registration-flow-sc') && !this.utility.route.url.includes('/step')) {
      this.utility.setReferral(this.utility.route.url.split('?')[0]?.replace(/^\//, ''));
    }
    if (this.utility.route.url.includes('/live/')) {
      this.utility.setReferral(this.utility.route.url.split('?')[0]?.replace(/^\//, ''));
    }
    if (this.utility.isBrowser) {
      this.googleAdsService.readTags();
    }
    this.VwoService.init()

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        pairwise(),
      )
      .subscribe((event: any) => {
        this.utility.setReferralUrl(event[0]?.url?.replace(/\?.*$/, ''));
      });

    this.router.events.subscribe((event: any) => {
      if (event instanceof ActivationStart) {
        if (event.snapshot.data['notfound']) {
          if (this.utility.isBrowser) {
            window.location.reload();
          }
        }
      }
      if (event instanceof NavigationEnd) {
        this.trackPageViewBySegments();
        this.VwoService.init()
        this.loading.setForceLoading(false)
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.utility.isBrowser) {
      this.scriptInjectService.appendStyle('late_styles.css');
      this.scriptInjectService.injectInitialScripts();
    }
    if (this.utility.isBrowser) {
      this.scriptInjectService.appendStyle('late_styles.css');
    }
  }

  @HostListener('document:click', ['$event'])
  @HostListener('window:scroll', ['$event'])
  @HostListener('mouseenter')
  @HostListener('mouseleave')
  onUserInteraction(event: Event) {
    if (!this.hasUserInteracted && this.utility.isBrowser) {
      this.hasUserInteracted = true;
      if (environment.production) {
        this.utility.getPageName.subscribe((el: any) => {
          this.utility.pageViewEvent(el);
        });
        this.addHsTracking();
      } else {
        this.addDevHsTracking();
      }
    }
  }

  trackPageViewBySegments() {
    let OriginalPageName = this.router.url.includes('?') ? this.router.url.substring(0, this.router.url.indexOf('?')) : this.router.url;
    let pageName = OriginalPageName === '/' ? 'home page v1.0' : OriginalPageName === '/en' ? 'home page v1.0' : OriginalPageName.substring(1)?.replace(/^en\//, '');
    if (!pageName.startsWith('registration-flow')  && !pageName.includes('tutorbooking') && !this.utility.isTutorbookingReferral() && !pageName.startsWith('tutor/') && !pageName.includes('live/') && !(pageName.includes('live/') && !pageName.includes('/result')) && !pageName.includes('lm/') && !(pageName.includes('lm/') && !pageName.includes('/result')) && !pageName.includes('el/')&& !pageName.includes('assessment/') && !pageName.includes(Slugs.market+'/')&& !pageName.includes('checkout/')) {
      this.segmentService.track('Website Page view', {
        pageName: decodeURI(pageName),
        lang: this.translateFacadeService.currLang,
      });
    }
    if (pageName.includes('checkout/')) {
      if (this.utility.isBrowser){
        this.VwoService.finished().subscribe(res => {
          this.segmentService.track('Website Page view', {
            pageName: decodeURI(pageName),
            lang: this.translateFacadeService.currLang,
          });
        })
      }
    }
  }

  addDevHsTracking() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = '//js.hs-scripts.com/23139402.js';
    this.renderer.appendChild(this.elementRef.nativeElement, script);
  }

  addHsTracking() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = '//js.hs-scripts.com/22322503.js';
    this.renderer.appendChild(this.elementRef.nativeElement, script);
  }
}
