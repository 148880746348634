import { inject, Injectable } from '@angular/core';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from './utility.service';
import { environment } from '../../../environments/environment';
import { GeoIpService } from './geo-ip.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import {LoggingService, SessionService} from "@services";
import {SIGNUP_FLOW_EVENT} from "../../features/signupflow/enums/event.enum";
import {MixpanelService} from "./mixpanel.service";
import mixpanel from "mixpanel-browser";
@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  localizeRouterService: LocalizeRouterService = inject(LocalizeRouterService);
  analytics = new AnalyticsBrowser();
  private loggingService: LoggingService = inject(LoggingService);

  prospectPage: BehaviorSubject<string> = new BehaviorSubject<string>('get started');
  prospectFlow: BehaviorSubject<string> = new BehaviorSubject<string>('');

  registrationPage: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  registrationLeadType: BehaviorSubject<string> = new BehaviorSubject<string>('');
  slug: BehaviorSubject<string> = new BehaviorSubject<string>('');
  sessionService: SessionService = inject(SessionService);
  MixpanelService: MixpanelService = inject(MixpanelService);

  constructor(public route: Router, private GeoIpService: GeoIpService, public utility: UtilityService, private activatedRoute: ActivatedRoute,
  ) {
    if (this.utility.isBrowser) {
    this.GeoIpService.geoIP().subscribe((el: any) => {
      if (el?.data?.countryCode == 'US') {
        return;
      } else {
       this.initSeqment();
      }
    }, () => {
      this.initSeqment();
    });
    }
  }

  initSeqment() {
    this.MixpanelService.init()
    this.analytics.load({
      writeKey: environment.segmentKey,
      cdnURL: 'https://sst.algooru.com',
    }, {
      integrations: {
        'Segment.io': {
          apiHost: 'sstapi.algooru.com/v1',
          protocol: 'https', // optional
        },
      },
    });
    this.analytics.addSourceMiddleware(({payload, next, integrations}) => {
      if (payload.obj.type === 'track' || payload.obj.type === 'page') {
        if (mixpanel.get_distinct_id()){
          const segmentDeviceId = payload.obj.anonymousId;
          // -------------------------------------------
          // Comment out one of the below mixpanel.register methods depending on your ID Management Version
          // Original ID Merge
          mixpanel.register({$device_id: segmentDeviceId, distinct_id: segmentDeviceId});
          // -------------------------------------------
          const sessionReplayProperties = mixpanel.get_session_recording_properties();
          payload.obj.properties = {
            ...payload.obj.properties,
            ...sessionReplayProperties
          };
        }
      }
      if (payload.obj.type === 'identify') {
        const userId = payload.obj.userId;
        if (mixpanel.get_distinct_id() && userId){
          mixpanel.identify(userId);
        }
      }
      next(payload);
    }).then(r =>{});
  }

  async track(eventName: string, properties?: object) {
    console.log(eventName,properties)
    try {
      const sessionId = this.sessionService.getSessionId();
      const updatedProperties = {
        ...properties,
        "session ID": sessionId
      };
      await this.analytics.track(eventName, updatedProperties);

      if (eventName.startsWith(SIGNUP_FLOW_EVENT)) {
        this.loggingService.logEventForSegmentFlow(eventName, {...properties});
      }
    }finally {
      return
    }
  }

  async userAnonymousId() {
    let id
    if (this.utility.isBrowser){
    id = localStorage.getItem('ajs_anonymous_id')?.replace(/"/g, '');
    }
    return id;
   }

  async identify() {
    return this.analytics.identify();
  }

  async pageView(name: string, path: string) {
    return this.analytics.page(name, { path });
  }


  clickHomeCta(cta: string) {
    const currentUrl = this.route.url;
     if (cta === 'Hp header cta') {
      this.track('Sign up flow started', { pageName: 'home page v1.0' });
      this.route.navigate([this.localizeRouterService.translateRoute('/registration-flow')], {
        queryParams: {
          'webpage_source': 'home page v1.0',
          'webpage_source_mix': 'header CTA',
        }, queryParamsHandling: 'merge',
      });
    } else if (this.utility.isTutorbookingReferral() || currentUrl.includes('/tutorbooking')) {
      this.track('Advantages template LP CTAs', {
        cta: 'header cta',
        'page name': currentUrl.split('?')[0].substring(1), //to remove / in the start
      });
      this.utility.route.navigate([this.localizeRouterService.translateRoute('/tutorbooking'), 'registration-flow'], {
        queryParams: {},
        queryParamsHandling: 'merge',
      });
    } else if (this.utility.isTrialSessionReferral() || currentUrl.startsWith('/trial-session')) {
      this.track('trial session flow started', {
        cta,
      });
      this.route.navigate([this.localizeRouterService.translateRoute('/trial-session'), 'step', '1'], {
        queryParams: {
          'webpage_source': 'trial-session',
          'webpage_source_mix': 'trial-session ' + cta,
        }, queryParamsHandling: 'merge',
      });
    } else if (this.utility.isTutorbookingReferral() || currentUrl.startsWith('/tutorbooking')) {
      this.track('Advantages self-checkout CTAs', { 'CTA': 'header' });
      this.route.navigate([this.localizeRouterService.translateRoute('/tutorbooking'), 'registration-flow'], {
        queryParams: {}, queryParamsHandling: 'merge',
      });
    } else if (this.utility.isAdvantagesSCReferral() || currentUrl.startsWith('/advantages-sc')) {
      this.track('Advantages self-checkout CTAs', { 'CTA': 'header' });
      this.route.navigate([this.localizeRouterService.translateRoute('/advantages-sc'), 'step', '1'], {
        queryParams: {
          webpage_source: 'advantages self-checkout',
          webpage_source_mix: 'advantages self-checkout',
        }, queryParamsHandling: 'merge',
      });
    }else if (this.utility.isAdvantagesReferral() || currentUrl.startsWith('/advantages')) {
      this.track('Advantages page CTAs', { CTA: 'CTA 1' });
      this.track('Sign up flow started', { pageName: 'advantages' });
      this.route.navigate([this.localizeRouterService.translateRoute('/registration-flow')], {
        queryParams: {
          webpage_source: 'advantages landing page', webpage_source_mix: 'Advantages Landing Page',
        }, queryParamsHandling: 'merge',
      });
    } else {
      this.track('Sign up flow started', { pageName: 'HP ' + cta });
      this.route.navigate([this.localizeRouterService.translateRoute('/registration-flow')], {
        queryParams: {
          'webpage_source': 'home page v1.0',
          'webpage_source_mix': 'HP ' + cta,
        }, queryParamsHandling: 'merge',
      });
    }
  }
}
