import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import {provideRouter, withDisabledInitialNavigation, withRouterConfig, withViewTransitions} from '@angular/router';
import { routes } from './app.routes';
import { initializeDirectionFactory, localizeLoaderFactory, multiTranslateLoaderFactory } from '@factories';
import { provideClientHydration } from '@angular/platform-browser';
import {
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { loadingInterceptor } from '@interceptors';
import { DataModule } from './data/data.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocalizeParser, LocalizeRouterSettings, withLocalizeRouter } from '@gilsdav/ngx-translate-router';
import { lang } from '@enums';
import { Location } from '@angular/common';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { trackingInterceptor } from './core/interceptors/tracking.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    // { provide: 'googleTagManagerId', useValue: 'GTM-MLV38RF' },
    provideRouter(
      routes,
      withViewTransitions(),
      withDisabledInitialNavigation(),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
      withLocalizeRouter(routes, {
        parser: {
          provide: LocalizeParser,
          useFactory: localizeLoaderFactory,
          deps: [
            TranslateService,
            Location,
            LocalizeRouterSettings,
            HttpClient,
          ],
        },
        initialNavigation: true,
        alwaysSetPrefix: false,
        useCachedLang: false,
        defaultLangFunction: () => lang.AR,
      }),
    ),
    provideAnimations(),
    provideClientHydration(),
    provideHttpClient(
      withFetch(),
      withInterceptorsFromDi(),
      withInterceptors([loadingInterceptor, trackingInterceptor]),
    ),
    importProvidersFrom([
      DataModule,
      RecaptchaV3Module,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: multiTranslateLoaderFactory,
          deps: [HttpClient],
        },
        defaultLanguage: 'ar',
      }),
    ]),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeDirectionFactory,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
};
