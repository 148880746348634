import { Injectable } from '@angular/core';
import mixpanel from "mixpanel-browser";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  constructor() { }
  init(){
    mixpanel.init(environment.mixpanelKey, {
      debug: false,
      track_pageview: false,
      record_sessions_percent: 100,
      record_mask_text_selector:'.input',
      record_block_selector:'',
      persistence: "localStorage",
    });
  }
}
