import { Routes } from '@angular/router';
import { signupRoutes } from './features/signupflowV0.1/routes';
import {marketRoutes} from "./features/market/routes";
import {assessmentRoutes} from "./features/assessment/routes";

export const routes: Routes = [
  {
    path: 'checkout',
    loadComponent: () => import('./shared/components/no-layout-main/main.component').then(m => m.NoLayoutMain),
    children: [
      {
        path: '',
        loadChildren: () => import('./payment-checkout-v2/payment-checkout-v2.module').then(m => m.PaymentCheckoutV2Module),
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
  {
    path: 'self-checkout/:paymentId',
    loadComponent: () =>
      import('./features/checkout/v1/v1.component').then(m => m.V1Component),
  },
  {
    path: 'auto-checkout/:paymentId',
    loadComponent: () =>
      import('./features/checkout/v2/v2.component').then(m => m.V2Component),
  },
  {
    path: 'payment-result',
    loadComponent: () => import('./shared/components/no-layout-main/main.component').then(m => m.NoLayoutMain),
    children: [
      {
        path: '',
        loadChildren: () => import('./payment-result/payment-result.module').then(m => m.PaymentResultModule),
      },
      {
        path: '**',
        loadComponent: () => import('./shared/components/not-found/not-found.component').then(m => m.NotFoundComponent),
        data: {
          notfound: true,
        },
      },
    ],
  },
  {
    path: '',
    loadComponent: () => import('./core/layout/main-layout/main-layout.component').then(m => m.MainLayoutComponent),
    children: [
      {
        path: 'thank-you',
        loadChildren: () => import('./thank-you/thank-you.module').then(m => m.ThankYouModule),
      },
      {
        path: 'join-riyadh-international-tutors',
        loadChildren: () => import('./international-curriculum-tutors/international-curriculum-tutors.module').then(m => m.InternationalCurriculumTutorsModule),
      },
      {
        path: 'careers',
        loadChildren: () => import('./careers-page/careers-page.module').then(m => m.CareersPageModule),
      },
      {
        path: 'university',
        loadChildren: () => import('./university/university.module').then(m => m.UniversityModule),
      },
      {
        path: 'join-as-a-tutor',
        loadChildren: () => import('./tutor-page/tutor-page.module').then(m => m.TutorPageModule),
      },
      {
        path: 'terms-of-service',
        loadChildren: () => import('./terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule),
      },
      {
        path: 'support',
        loadChildren: () => import('./support-policy/support-policy.module').then(m => m.SupportPolicyModule),
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
      },
      {
        path: 'about-us',
        loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule),
      },
      {
        path: 'parents',
        loadChildren: () => import('./parent-page/parent-page.module').then(m => m.ParentPageModule),
      },
      {
        path: 'educational-resources',
        loadChildren: () => import('./educational-resources/educational-resources.module').then(m => m.EducationalResourcesModule),
      },
      {
        path: 'blog',
        loadChildren: () => import('./blogs/blogs.module').then(m => m.BlogsModule),
      data: { skipRouteLocalization: { localizeRedirectTo: true } },
      },
      // {
      //   path: 'en/blog',
      //   loadChildren: () => import('./blogs/blogs.module').then(m => m.BlogsModule),
      // },
      {
        path: 'advantages',
        loadChildren: () => import('./advantages/advantages.module').then(m => m.AdvantagesModule),
      },
      {
        path: 'live',
        loadChildren: () => import('./webinar/webinar.module').then(m => m.WebinarModule),
      },
      {
        path: 'en/lm',
        loadChildren: () => import('./lead-magnet/lead-magnet.module').then(m => m.LeadMagnetModule),
      },
      {
        path: 'lm',
        loadChildren: () => import('./lead-magnet/lead-magnet.module').then(m => m.LeadMagnetModule),
      },
      {
        path: 'our-tutors',
        loadChildren: () => import('./tutor-list/tutor-list.module').then(m => m.TutorListModule),
      },
      {
        path: 'tutor',
        loadChildren: () => import('./tutor-details/tutor-details.module').then(m => m.TutorDetailsModule),
      },
      {
        path: 'why-algooru',
        loadChildren: () => import('./credibility/credibility.module').then(m => m.CredibilityModule),
      },
      {
        path: 'request',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'become-a-partner',
        loadChildren: () => import('./partnership/partnership.module').then(m => m.PartnershipModule),
      },
      {
        path: '',
        loadComponent: () =>
          import('./features/home/home.component').then((c) => c.HomeComponent),
      },
      {
        path: 'prices',
        loadChildren: () => import('./pricing/pricing.module').then(m => m.PricingModule),
      },
      {
        path: 'learn',
        loadChildren: () => import('./subjects/subjects.module').then(m => m.SubjectsModule),
      },
      {
        path: 'assessment',
        children: assessmentRoutes,
      },
      {
        path: 'registration-flow',
        loadComponent: () => import('./features/main-signup-flow/main-signup-flow.component').then(m => m.MainSignupFlowComponent),
        children: signupRoutes,
      },
      {
        path: 'curriculums',
        loadChildren: () => import('./curriculums/curriculums.module').then(m => m.CurriculumsModule),
      },
      {
        path: 'tutorbooking',
        loadChildren: () => import('./tutorbooking/tutorbooking.module').then(m => m.TutorbookingModule),
      },
      {
        path: 'market',
        children: marketRoutes,
      },
      {
        path: 'el',
        loadChildren: () => import('./experiment-lp/experiment-lp.module').then(m => m.ExperimentLpModule),
      },
      {
        path: 'en/el',
        loadChildren: () => import('./experiment-lp/experiment-lp.module').then(m => m.ExperimentLpModule),
      },
      {
        path: 'testimonials',
        loadChildren: () => import('./testimonial-page/testimonial-page.module').then(m => m.TestimonialPageModule),
      },
      {
        path: 'en/testimonials',
        loadChildren: () => import('./testimonial-page/testimonial-page.module').then(m => m.TestimonialPageModule),
      },
      {
        path: 'courses/:slug',
        loadComponent: () => import('./features/courses/courses.component').then(m => m.CoursesComponent),
      },
      {
        path: 'testimonials',
        loadChildren: () => import('./testimonial-page/testimonial-page.module').then(m => m.TestimonialPageModule),
      },
      {
        path: 'en/testimonials',
        loadChildren: () => import('./testimonial-page/testimonial-page.module').then(m => m.TestimonialPageModule),
      },
    ],
  },
  {
    path: '**',
    loadComponent: () => import('./shared/components/not-found/not-found.component').then(m => m.NotFoundComponent),
    data: {
      notfound: true,
    },
  },
];
