import { inject, Injectable } from '@angular/core';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { take } from 'rxjs';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateFacadeService } from '@services';
import { SegmentService } from '../../shared/services/segment.service';
import { UtilityService } from '../../shared/services/utility.service';
import { clickId } from '../../shared/constants/mixpanel';
import { NELCService } from '../../shared/services/nelc.service';
import {Slugs} from "@enums";

SwiperCore.use([Autoplay, Pagination, Navigation]);

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  utility: UtilityService = inject(UtilityService);
  segmentService: SegmentService = inject(SegmentService);

  innerWidth: any;
  clickId = clickId;
  localizeRouterService: LocalizeRouterService = inject(LocalizeRouterService);
  public NELCService: NELCService = inject(NELCService);
  showWebinarNewLeadDialog = false;
  showLeadMagnetNewLeadDialog = false;
  translateFacadeService: TranslateFacadeService = inject(TranslateFacadeService);

  get showCTAOnHeader() {
    return !this.utility.route.url.includes('parents') && !this.utility.isHomePage && !(this.utility.route.url.includes('/live') && this.utility.route.url.includes('/result')) && !(this.utility.route.url.includes('/lm') && this.utility.route.url.includes('/result')) && !this.utility.route.url.includes('/registration-flow') && !this.utility.route.url.includes('become-a-partner/signup') && !this.utility.PageRouteService.isAssessmentTestPage && !this.utility.isLeadMagnet;
  }

  get showChangeLanguage() {
    return (!this.utility.isArabicOnlyByValue(this.utility.route.url) && !this.utility.isEnglishOnlyByValue(this.utility.route.url)
      && this.utility.thankYouSource != 'Parent' && !this.utility.isNewSignUp()
      && !this.utility.isExperimentPage && !this.utility.isMarketPageWithFlow && !this.utility.isWebinar);

  }

  navigateToWhatsapp(pageName:string){
    if (this.utility.isBrowser){
      this.segmentService.track('WA flow started',{'page name':pageName})
      window.open('https://wa.me/+966115102962', '_blank');
    }
  }

  get checkMobile() {
    let ua;
    if (this.utility.isBrowser) ua = navigator.userAgent;
    if (ua && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
      return true;
    else
      return false;
  }

  get checkIOS() {
    let ua;
    if (this.utility.isBrowser) ua = navigator.userAgent;
    if (ua && /iPhone|iPad|iPod/i.test(ua))
      return true;
    else
      return false;
  }

  clickCta(url: string) {
    if (!url.includes('trial-session') && !url.includes('advantages') && !url.includes('advantages-sc')) {
      this.segmentService.clickHomeCta('Hp header cta');
    } else {
      this.segmentService.clickHomeCta('cta 1');
    }
  }

  async clickSignupPage(queryParams?: any, type?: string, isHeader?: boolean, cta?: any, isNELC = false) {
    if (this.utility.isTrialSessionReferral()) {
      this.segmentService.track('trial session flow started');
      this.utility.route.navigate([this.localizeRouterService.translateRoute('/trial-session'), 'step', '1'], {
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
    } else {
      let ctaType: string;
      let city = this.utility.activatedRoute.snapshot.queryParamMap.get('city');
      let subject = this.utility.activatedRoute.snapshot.queryParamMap.get('subject');
      if (cta) {
        ctaType = cta;
      } else {
        ctaType = queryParams?.webpage_source;
      }
      if (queryParams.webpage_source_mix?.startsWith('Subjects')) {
        ctaType = queryParams?.webpage_source_mix;
      }
      if (queryParams.isTutor) {
        if (type == 'tutorList') {
          ctaType = queryParams.webpage_source_mix;
        } else {
          let OriginalPageName = this.utility.route.url.includes('?') ? this.utility.route.url.substring(0, this.utility.route.url.indexOf('?')) : this.utility.route.url;
          OriginalPageName = OriginalPageName.substring(1).replace(/^en\//, '');
          ctaType = decodeURI(OriginalPageName);
        }
      }
      if (queryParams.webpage_source == 'about-us') {
        ctaType = queryParams.webpage_source_mix;
      }
      if (queryParams.webpage_source == 'advantages landing page') {
        ctaType = 'advantages';
      }
      if (isHeader) {
        if (type =='market'){
          this.segmentService.track('Market template LP CTAs', { 'cta':'header cta','pageName':'market/'+queryParams.slug });
        }
        else{
          this.segmentService.track('header CTA', { 'pageName': ctaType, city, subject });
        }
      }
      if (isNELC) {
        ctaType = this.utility.seqmentPageName;
      }
      else if (type == 'Experiment template LP') {
        if (isNELC) {
          await this.segmentService.track('Sign up flow started', {
            pageName: ctaType,
          });
        }
        this.utility.route.navigate([this.localizeRouterService.translateRoute('/el'), 'registration-flow'], {
          queryParams: queryParams,
          queryParamsHandling: 'merge',
        });
      }
      else if (type == 'market') {
        if (isNELC) {
          await this.segmentService.track('Sign up flow started', {
            pageName: ctaType,
          });
        }
        this.utility.route.navigate([this.localizeRouterService.translateRoute('/'+Slugs.market), 'registration-flow'], {
          queryParams: queryParams,
          queryParamsHandling: 'merge',
        });
      } else if (type == 'tutorbooking') {
        if (isNELC) {
          await this.segmentService.track('Sign up flow started', {
            pageName: ctaType,
          });
        }
        this.utility.route.navigate([this.localizeRouterService.translateRoute('/tutorbooking'), 'registration-flow'], {
          queryParams: queryParams,
          queryParamsHandling: 'merge',
        });
      } else if (type == 'Experiment template LP') {
        if (isNELC) {
          this.segmentService.track('Sign up flow started', {
            pageName: ctaType,
          });
        }
        this.utility.route.navigate([this.localizeRouterService.translateRoute('/el'), 'registration-flow'], {
          queryParams: queryParams,
          queryParamsHandling: 'merge',
        });
      } else if (type == 'assessment') {
        this.segmentService.track('Sign up flow started', {
          pageName: queryParams?.webpage_source_mix,
        });
        this.utility.route.navigate([this.localizeRouterService.translateRoute('/registration-flow')], {
          queryParams: queryParams,
          queryParamsHandling: 'merge',
        });
      } else {
        this.segmentService.track('Sign up flow started', {
          pageName: ctaType, city, subject,
        });
        this.utility.route.navigate([this.localizeRouterService.translateRoute('/registration-flow')], {
          queryParams: queryParams,
          queryParamsHandling: 'merge',
        });
      }
    }
  }

  isSelectedPage(url: string) {
    return (decodeURIComponent(this.utility.selectedPage||'')?.includes(url) || this.utility.selectedPage?.includes(url + '/') || this.utility.selectedPage?.includes('/' + url));
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  scrollById(id: string) {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  }

  getTutorImage(key: any) {
    return `assets/${this.utility.isRtl ? `Teacher photo ar${key}` : `Teacher photo en${key}`}.png`;
  }

  getTranslate(key: any) {
    return new Promise(resolve => {
      this.utility.translate.get(key).pipe(
        take(1),
      )
        .subscribe(
          (data: any) => {
            resolve(data);
          });
    });
  }

  numberConverter(number: any) {
    // @ts-ignore
    return number.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));
  }

  numberConverterE2A(number: any) {
    // @ts-ignore
    return number.replace(/\d/g, d => '٠١٢٣٤٥٦٧٨٩'[d]);
  }

  saudiCode(number: string = '') {
    return '+966' + number;
  }

  navigateToRoute(route?: any) {
    if (route) {
      if (this.utility.isArabicOnlyByValue(route)) {
        this.utility.route.navigate([route], { queryParamsHandling: 'merge' });
      } else {
        this.utility.route.navigate([this.utility.languageCode, route], { queryParamsHandling: 'merge' });
      }
    } else {
      this.utility.route.navigate([this.utility.languageCode], { queryParamsHandling: 'merge' });
    }
  }

  stringFromUrl(url: any, separator: string = '-') {
    return url?.split(separator).join(' ');
  }

  stringToUrl(string: string, separator: string = '-') {
    return string?.split(' ').join(separator);
  }

  formSubmitEvent(lead: any, flow: any) {
    let isNELC = this.utility.activatedRoute.snapshot.queryParamMap.get('isNELC');
    this.segmentService.track('Form Submit', {
      ...lead,
      source: flow,
      isNELC: !!isNELC,
      lang: this.translateFacadeService.currLang,
    });
  }
}
