import { inject, Injectable } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { lang } from '@enums';
import {LoadingService} from "./loading.service";

@Injectable({
  providedIn: 'root',
})
export class TranslateFacadeService {
  localizeRouterService = inject(LocalizeRouterService);
  private readonly translateService = inject(TranslateService);
  loading = inject(LoadingService);

  get currLang() {
    return this.localizeRouterService.parser.currentLang as lang;
  }
  get isRTL() {
    return this.currLang == 'ar';
  }
  translateKey(key: string) {
    return this.translateService.instant(key);
  }

  changeLanguage() {
    this.loading.setForceLoading(true)
    this.currLang === lang.AR
      ? this.localizeRouterService.changeLanguage(lang.EN, {
          onSameUrlNavigation: 'reload',
        })
      : this.localizeRouterService.changeLanguage(lang.AR, {
          onSameUrlNavigation: 'reload',
        });
  }
}
